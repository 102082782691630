/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Pakcages
import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import axios from 'axios';

// UI lib comoponents
import { Row, Col } from 'react-grid-system';

// Local UI components
import InputField from '../../../shared/UIKit/InputField';
import FormButton from '../../../shared/UIKit/FormButton';
// Redux actions and selectors

// Helpers & utils

// Style
import './index.scss';
import { baseUrl } from '../../../shared/APIs';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function PasswordResetSection() {
  /* ********************************** HOOKS ********************************* */
  const [response, setResponse] = useState();
  const [loading, setLoading] = useState(false);

  /* ******************************** CALLBACKS ******************************* */
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const fieldValues = Object.fromEntries(formData.entries());

    setLoading(true);
    try {
      await axios.post(`${baseUrl}/auth/forgot-password`, {
        email: fieldValues?.email,
      });
      setResponse('SUCCESS');
    } catch (error) {
      setResponse('ERROR');
    }
    setLoading(false);
  };

  useEffect(() => {
    if (!response) return;
    setTimeout(() => {
      setResponse();
    }, 5000);
  }, [response]);

  /* ******************************** RENDERING ******************************* */

  return (
    <Col className="password-reset-section">
      <Row>
        <div className="login-link">
          <p>Vous avez déja un compte?</p>
          <Link to="/login"> Se connecter</Link>
        </div>
      </Row>
      <Row align="center" justify="center" className="form-container">
        <Col lg={7} justify="center">
          <form onSubmit={handleSubmit}>
            <h2>Besoin d’aide pour vous connecter ?</h2>
            <p>
              Entrez votre adresse pour recevoir un lien de réinitialisation
            </p>
            <Row>
              <Col lg={12} xl={12} md={12} sm={12} xs={12}>
                <InputField name="email" label="email" type="email" required />
              </Col>
            </Row>
            <Row
              justify={response ? 'between' : 'end'}
              align="center"
              style={{
                marginTop: '2rem',
              }}
            >
              {response && (
                <Col>
                  <span
                    className={`response ${
                      response === 'ERROR' ? 'error' : 'SUCCESS'
                    }`}
                  >
                    {response === 'ERROR'
                      ? "Une erreur s'est produite. Veuillez réessayer"
                      : 'nous vous avons envoyé un e-mail pour réinitialiser votre mot de passe'}
                  </span>
                </Col>
              )}
              <Col lg="content">
                <FormButton text="Envoyer" loading={loading} />
              </Col>
            </Row>
          </form>
        </Col>
      </Row>
    </Col>
  );
}

PasswordResetSection.propTypes = {};

PasswordResetSection.defaultProps = {};

export default PasswordResetSection;
