/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Packages
import * as React from 'react';
import { graphql } from 'gatsby';

// Lib UI components
import { Row } from 'react-grid-system';

// Local UI components

import Seo from '../shared/Seo';
import ImageTextOverlay from '../shared/UIKit/ImageTextOverlay';
import PasswordResetSection from '../page-components/ForgetPassword/PasswordResetSection';
// Style
import '../page-styles/ForgetPassword.scss';

/* -------------------------------------------------------------------------- */
/*                                    Page                                    */
/* -------------------------------------------------------------------------- */

function ForgetPassword() {
  /* ********************************** HOOKS ********************************* */

  // Localization

  /* ******************************** RENDERING ******************************* */
  return (
    <>
      <Seo title="Forget password" />
      <Row>
        <PasswordResetSection />
        <ImageTextOverlay />
      </Row>
    </>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["ForgetPassword", "Common"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

export default ForgetPassword;
